<template>
  <b-row class="match-height">
    <b-col sm="8">
      <b-card :class="{ 'mb-0': currentBreakPoint === 'xs' }">
        <div>
          <div class="d-flex flex-row mb-1">
            <div class="flex-grow-1">
              <h3>{{ product.product_catalog.name }}</h3>
              <div class="d-flex flex-column">
                <span class="text-muted h5">{{ product.serial_number }}</span>
                <div class="d-flex">
                  {{ $t('quantity') }}:
                  <div class="d-flex mx-50">
                    {{ product.quantity }}
                    <span
                      v-if="quote.is_express"
                      class="mx-50 d-flex align-items-center"
                    >
                      <pre class="m-0">{{ `- ` }}</pre>
                      <b-tooltip
                        :target="`tooltip-express-${product.id}`"
                        triggers="hover"
                        boundary-padding="10"
                      >
                        <span>
                          Entrega promedio en
                          {{ product.is_express ? '24' : '72' }} Hrs.
                        </span>
                      </b-tooltip>
                      <b-badge
                        :id="`tooltip-express-${product.id}`"
                        :variant="product.is_express ? 'success' : 'secondary'"
                      >
                        {{
                          product.is_express
                            ? $t('expressdelivery')
                            : $t('ordinarydelivery')
                        }}</b-badge>
                    </span>
                  </div>
                  {{ $tc(unitsFilter(product.unit_type), product.quantity) }}
                </div>
              </div>
            </div>
            <div>
              <b-img
                fluid
                width="70px"
                :src="product.product_catalog.logo"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <h5>{{ $t('description') }}</h5>
              <div class="text-justify text-muted">
                {{ product.product_catalog.description }}
              </div>
            </div>
            <b-button
              v-if="quote.is_express && !product.is_express"
              size="sm"
              variant="danger"
              class="d-flex custome-button"
              @click="deleteItem"
            >
              <feather-icon
                icon="TrashIcon"
                class="custom-margin"
              />
            </b-button>
          </div>
        </div>
      </b-card>
    </b-col>
    <!--   <b-col sm="4">
      <b-card
        :bg-variant="selectedOption === 2 ? 'success' : 'white'"
        :text-variant="selectedOption === 2 ? 'white' : ''"
      >
        <provider-option-card
          :provider-option="product"
          :background-green="selectedOption === 2"
        />
      </b-card>
    </b-col> -->
    <b-col sm="4">
      <b-card
        :bg-variant="selectedOption === 1 ? 'success' : 'white'"
        :text-variant="selectedOption === 1 ? 'white' : ''"
      >
        <provider-option-card
          :provider-option="product"
          :background-green="selectedOption === 1"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { BTooltip } from 'bootstrap-vue'
import ProviderOptionCard from '@/components/pricing/ProviderOptionCard.vue'

export default {
  components: {
    ProviderOptionCard,
    BTooltip,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    selectedOption: {
      type: Number,
      default() {
        return 1
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('app', ['unitsFilter', 'currentBreakPoint', 'isSmallScreen']),
    ...mapGetters('quotes', ['quote']),
  },

  methods: {
    ...mapMutations('quotes', {
      setQuoteItems: 'SET_QUOTE_ITEMS',
    }),
    ...mapActions('quotes', ['fetchQuote', 'deleteQuoteItems']),
    async deleteItem() {
      const items = [{ quote_item_id: this.product.id }]
      await this.deleteQuoteItems({
        quoteId: this.quote.id,
        items,
      })
      await this.fetchQuote(this.quote.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-best-price {
  background-color: #77c77b;
}
.custom-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>

<template>
  <div>
    <b-row
      class="match-height header-products"
      :class="{ 'position-sticky': currentBreakPoint !== 'xs' }"
    >
      <b-col sm="8">
        <b-card class="d-flex justify-content-center align-items-center">
          <h2>
            {{ $t('myList') }} ({{ products.length }} {{ $t('products') }})
          </h2>
        </b-card>
      </b-col>
      <!--    <b-col sm="4">
        <b-card
          class="d-flex justify-content-center align-items-center text-center"
        >
          <h2 class="">
            {{ $t('bestDeliveryTime') }}
          </h2>
          <div>
            <b-button
              :class="selectedOption === 2 ? 'bg-yellow' : ''"
              :variant="selectedOption === 2 ? 'bg-yellow' : 'primary'"
              @click="selectedOption = 2"
            >
              <feather-icon
                v-if="selectedOption === 2"
                icon="CheckIcon"
              />
              <span v-else>{{ $t('select') }}</span>
            </b-button>
          </div>
        </b-card>
      </b-col> -->
      <b-col
        v-if="currentBreakPoint !== 'xs'"
        sm="4"
      >
        <b-card
          class="d-flex justify-content-center align-items-center text-center"
        >
          <h2>
            {{ $t('bestPrice') }}
          </h2>
          <div class="w-100">
            <b-button
              :class="selectedOption === 1 ? 'bg-yellow' : ''"
              :variant="selectedOption === 1 ? 'bg-yellow' : 'primary'"
              @click="selectedOption = 1"
            >
              <feather-icon
                v-if="selectedOption === 1"
                icon="CheckIcon"
              />
              <span v-else>{{ $t('select') }}</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          v-for="(supplierId, index) in supplierKeys"
          :key="supplierId"
        >
          <div
            v-if="supplierId === 'notFound'"
            class="mb-2"
          >
            <h2 class="font-weight-bolder">
              {{ $t('notFoundProducts') }}
            </h2>
          </div>
          <div
            v-else-if="quote.limit_suppliers"
            class="mb-2"
          >
            <h2 class="font-weight-bolder">
              {{ $t('supplier') }} {{ index + 1 }}
            </h2>
          </div>
          <div
            v-for="product in supplierProducts[supplierId]"
            :key="product.id"
          >
            <client-product-row
              :selected-option="selectedOption"
              :product="product"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-start justify-content-sm-end mt-0">
      <div class="bg-white p-2 rounded d-flex flex-column">
        <div class="h2 d-flex justify-content-between">
          {{ $t('subtotal') }}:
          <span class="ml-2"> ${{ quote.subtotal | money }} </span>
        </div>
        <div class="h2 d-flex justify-content-between">
          {{ $t('shipping') }}:
          <span class="ml-2 text-muted">
            ${{ quote.shipping_total | money }}
          </span>
        </div>
        <div class="h2 d-flex justify-content-between border-bottom-dark">
          {{ $t('tax') }}:
          <span class="ml-2 text-muted"> ${{ quote.iva_total | money }} </span>
        </div>
        <div class="h2 d-flex justify-content-between text-success">
          {{ $t('total') }}:
          <span class="ml-2"> ${{ quote.total | money }} </span>
        </div>
        <div class="mt-2">
          <b-button
            block
            variant="primary"
            @click="accept()"
          >
            {{ $t('accept') }}
          </b-button>
          <b-button
            block
            variant="outline-danger"
            @click="reject()"
          >
            {{ $t('reject') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ClientProductRow from '@/components/pricing/ClientProductRow.vue'

export default {
  components: {
    ClientProductRow,
  },
  props: {
    quote: {
      type: Object,
      default: () => {},
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedOption: 1,
      supplierProducts: {},
    }
  },

  computed: {
    ...mapGetters('app', ['currentBreakPoint', 'isSmallScreen']),
    subtotal() {
      return this.products.reduce((prev, next) => ({
        total: parseFloat(prev.total) + parseFloat(next.total),
      }))
    },

    supplierKeys() {
      return Object.keys(this.supplierProducts).sort((a, b) => {
        if (a === 'notFound') {
          return 1 // Mueve 'notFound' al final
        }
        if (b === 'notFound') {
          return -1 // Mantiene 'notFound' al final
        }
        return a.localeCompare(b)
      })
    },
  },

  watch: {
    products: {
      handler(items) {
        const hMap = {}
        items.forEach(i => {
          const supplierId = i.selected_supplier_branch_id || 'notFound'
          if (hMap[supplierId]) {
            hMap[supplierId].push(i)
          } else {
            hMap[supplierId] = [i]
          }
        })
        this.supplierProducts = hMap
      },
      immediate: false,
    },
  },

  mounted() {
    const items = [...this.products]
    const hMap = {}
    items.forEach(i => {
      const supplierId = i.selected_supplier_branch_id || 'notFound'
      if (hMap[supplierId]) {
        hMap[supplierId].push(i)
      } else {
        hMap[supplierId] = [i]
      }
    })
    this.supplierProducts = hMap
  },

  methods: {
    ...mapActions('quotes', ['acceptQuote', 'rejectQuote']),

    accept() {
      this.acceptQuote({ quoteId: this.$route.params.id }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('quoteAccepted'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'checkout',
          params: { quoteId: this.$route.params.id },
        })
      })
    },
    reject() {
      this.rejectQuote({ quoteId: this.$route.params.id }).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('quoteRejected'),
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.$router.push({
          name: 'quotes',
          params: { projectId: response.project_id },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header-products {
  top: 90px;
  z-index: 10;
}
.bg-best-price {
  background-color: #77c77b;
}
.custom-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}

.bg-yellow {
  background-color: #ffee52;
}
</style>
